<template>
  <v-col cols="12">
    <v-dialog
      v-model="openCreateModal"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <div class="col-sm-12 text-center">
            <span v-if="createPayLoad.id">
              Update Facility Playlist
            </span>
            <span v-else>
              Create new facility list
            </span>
          </div>
          <v-row>
            <FacilitiesWarning
              :disableAlert="disableAlert"
            />
          </v-row>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <!-- Assign day text field -->
            <v-row>
              <v-col cols="8">
                <text-field
                  ref="nameField"
                  v-model="createPayLoad.title"
                  label="Name"
                  class="playlist-text-field"
                  required
                  :outlined="false"
                  focused
                  @blur="createPayLoad.title = $custom.toNameCase(createPayLoad.title)"
                ></text-field>
              </v-col>
            </v-row>
            <!-- End Assign day text field -->

            <!-- Color picker -->
            <v-row>
              <v-col cols="12">
                <div ref="colorPickerWrapper">
                  <p class="mb-0">
                    Color
                  </p>
                  <v-color-picker
                    ref="colorPicker"
                    v-model="createPayLoad.color"
                    width="100%"
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    hide-sliders
                    show-swatches
                    :swatches="colorSwatches"
                    :elevation="0"
                    class="custom-swatches"
                  ></v-color-picker>
                  <div
                    v-if="colorError"
                    class="error--text"
                  >
                    {{ colorError }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <!-- End color picker -->

            <!-- Selected Facilities list -->
            <v-row>
              <v-col cols="12">
                <p
                  v-if="favoriteFacilities.length < 1"
                  class="text-center font-italic"
                >
                  Add facilities to your list
                </p>
                <div v-else>
                  <v-data-table
                    :headers="headers"
                    :items="favoriteFacilities"
                    item-key="id"
                    :hide-default-header="true"
                    hide-default-footer
                  >
                    <template v-slot:item.title="{ item }">
                      {{ item.title }}
                    </template>

                    <template v-slot:item.id="{ item }">
                      <v-btn
                        :plain="true"
                        :x-small="true"
                        color="secondary"
                        @click="removeFacilityFromFavorites(item)"
                      >
                        <v-icon>{{ icons.mdiMinus }}</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
            <!-- End Selected Facilities list -->

            <!-- Facilities -->
            <v-row>
              <v-col
                cols="12"
                class="add-facility-playlist-modal"
              >
                <v-data-table
                  v-model="selectedFacilities"
                  :headers="headers"
                  :items="facilitiesLocal"
                  item-key="id"
                  :search="search"
                  hide-default-footer
                  :page.sync="paginator.page"
                  :items-per-page="paginator.itemsPerPage"
                  @page-count="paginator.pageCount = $event"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Search Facilities"
                      class="mx-4 playlist-text-field"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.title="{ item }">
                    {{ item.title }}
                  </template>

                  <template v-slot:item.id="{ item }">
                    <v-btn
                      :plain="true"
                      :x-small="true"
                      color="primary"
                      @click="addFacilityToFavorites(item)"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>

                <pagination
                  :page.sync="paginator.page"
                  :items-per-page.sync="paginator.itemsPerPage"
                  :page-count="paginator.pageCount"
                ></pagination>
              </v-col>
            </v-row>
            <!-- End Facilities -->

            <!-- Buttons -->
            <v-row>
              <div
                class="d-flex justify-end w-100"
              >
                <v-btn
                  :x-small="true"
                  color="primary"
                  @click="closeForm"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :x-small="true"
                  color="success"
                  class="ml-2"
                  :disabled="isSaving"
                  @click="saveFacilityPlaylist"
                >
                  <v-icon left>
                    {{ icons.mdiCloud }}
                  </v-icon>
                  Save List
                </v-btn>
              </div>
            </v-row>
            <!-- End Buttons -->
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import FacilitiesWarning from '@/components/elements/facility/FacilitiesWarning.vue';
import FacilityPlaylist from '@/mixins/FacilityPlaylist';
import syncLogSetup from '@/mixins/SyncEncounters';
import {
  mdiCloud,
  mdiMinus,
  mdiPlus,
  mdiSync
} from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  mixins: [FacilityPlaylist, syncLogSetup],
  components: {
    FacilitiesWarning,
  },
  props: {
    openCreateModal: {
      type: Boolean,
      required: false,
    },
    createPayLoad: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: '',
        color: '#FF0000',
        is_favorite: false,
        place_of_service_ids: [],
      }),
    },
  },
  data() {
    return {
      disableAlert: false,
      search: '',
      isSaving: false,
      facilitiesLocal: [],
      selectedFacilities: [],
      favoriteFacilities: [],
      disableAddFacilityBtn: false,
      facilityPlaylist: [],
      colorError: '',
      colorSwatches: [
        ['#FF0000', '#FF8400'], // Red, orange
        ['#FFD900', '#00DE4A'], // Yellow, Green
        ['#1E74FF', '#BB38D5'], // Blue, Purple
        ['#FF688E', '#8C6032'], // Pink, Brown
        ['#979797', '#36CFC9'], // Gray, Teal
      ],
      paginator: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      headers: [
        {
          text: 'Name', value: 'title', sortable: false,
        },
        {
          text: 'Actions', value: 'id', sortable: false,
        },
      ],
      icons: {
        mdiPlus,
        mdiMinus,
        mdiCloud,
        mdiSync,
      },
    }
  },
  computed: {
    ...mapGetters('facilities', {facilities: 'facilities', maxFacilities: 'maxFacilities'}),
  },
  watch: {
    facilities(newVal) {
      this.facilitiesLocal = newVal
    },
    openCreateModal(newVal) {
      this.favoriteFacilities = []
      this.disableAlert = false
      this.facilitiesLocal = this.facilities
      if (newVal === true && this.createPayLoad.place_of_service_ids.length > 0) {
        this.favoriteFacilities = this.facilities.filter(facility =>
          this.createPayLoad.place_of_service_ids.includes(facility.id)
        )

        if (this.favoriteFacilities.length > this.maxFacilities) {
          this.disableAlert = true
        }
        this.updateFacilitiesList()
        this.facilityPlaylist = this.createPayLoad
      } else {
        this.$refs.form?.reset()
      }
    },
  },
  methods: {
    removeFacilityFromFavorites(facility) {
      this.favoriteFacilities.splice(this.favoriteFacilities.indexOf(facility), 1)

      if (this.favoriteFacilities.length < this.maxFacilities) {
        this.disableAlert = false
      }

      this.updateFacilitiesList()
    },

    addFacilityToFavorites(facility) {
      this.favoriteFacilities.push(facility)

      if (this.favoriteFacilities.length > this.maxFacilities) {
        this.disableAlert = true
        const indexToRemove = this.favoriteFacilities.findIndex(fac => fac === facility);
        if (indexToRemove !== -1) {
          this.favoriteFacilities.splice(indexToRemove, 1);
        }
      }

      this.updateFacilitiesList()
    },

    updateFacilitiesList() {
      this.facilitiesLocal = this.facilities.filter(facility =>
        !this.favoriteFacilities.some(favoriteFacility => favoriteFacility.id === facility.id)
      );
    },

    async saveFacilityPlaylist() {
      this.isSaving = true
      if (!this.validatePayload()) {
        this.isSaving = false
        return
      }

      const isOnline = this.checkIfOnline();

      if (!isOnline) {
        this.isSaving = false
        return
      }

      this.$store.dispatch('encounters/syncingModal', true)
      // for some reason vue's swatches adds an extra character to the color
      if (this.createPayLoad.color.length > 7) {
        this.createPayLoad.color = this.createPayLoad.color.substring(0, 7)
      }

      this.createPayLoad.place_of_service_ids = this.favoriteFacilities.map(facility => facility.id)
      this.createPayLoad.sync_log = this.syncLogSetup('Facility Playlist: Sync(Save)')

      const isFacilityPlaylistSaved = await this.$store.dispatch('auth/syncFacilityPlaylist', this.createPayLoad)

      this.isSaving = false
      this.$store.dispatch('encounters/syncingModal', false)
      if (!isFacilityPlaylistSaved) {
        return
      }

      this.close()
    },

    async closeForm() {

      const isConfirmed = await this.$root.confirm({
        title: 'Discard',
        subTitle: 'Discard all changes?',
        cancel: "No",
        confirm: "Yes, Discard",
        confirmColor: "error",
      })

      if (isConfirmed) {
        this.close()
      }
    },

    validatePayload() {
      const { nameField, colorPickerWrapper } = this.$refs

      const isTitleValid = !!this.createPayLoad.title
      const isColorValid = !!this.createPayLoad.color

      // If not valid, scroll to the first invalid field
      if (!isTitleValid) {
        nameField.$refs.textField.focus()
        nameField.$refs.textField.blur()
        nameField.$refs.textField.$el.scrollIntoView({ behavior: 'smooth' })

        return false
      }

      if (!isColorValid) {
        colorPickerWrapper.scrollIntoView({ behavior: 'smooth' })
        this.colorError = 'Color is required'

        return false
      }

      if (this.favoriteFacilities.length < 1) {
        return false
      }

      const isTitleExist = this.favoriteFacilities.some(facilityPlaylist => facilityPlaylist.title === this.createPayLoad.title)

      if (isTitleExist) {
        Vue.store.dispatch('notify', { value: ExistsMessage, color: 'error' })
        return false
      }

      return true
    },
  },
}
</script>

<style scoped>

.add-facility-playlist-modal >>> .pagination {
  justify-content: center !important;
  padding-left: 0 !important;
}

.add-facility-playlist-modal >>> .v-select {
  display: none !important;
}

.error--text {
  color: red;
}

.custom-swatches >>> .v-color-picker__color {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  max-height: 25px;
}

.custom-swatches >>> .v-color-picker__swatch {
  flex-direction: row !important;
}

.custom-swatches >>> .v-color-picker__swatches {
  & > div {
    margin: 0;
    padding: 0;
    justify-content: start;
  }
}

</style>
