<template>
  <v-row
    justify="center"
    class="col-sm-12"
  >
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          My Facilities List
          <v-spacer></v-spacer>
          <div>
            <btn
              class="ml-5"
              label="Create"
              color="success"
              :x-small="false"
              :icon="icons.mdiPlus"
              @click="openCreatePlaylistModal()"
            ></btn>
          </div>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-tabs
        fixed-tabs
        class="borderless-tabs"
      >
        <v-tab href="#allFacilityPlaylists">
          All
        </v-tab>
        <v-tab-item
          class="borderless-tabs"
          value="allFacilityPlaylists"
        >
          <v-data-table
            class="w-100"
            :headers="allTabHeaders"
            :items="myFacilityPlaylist"
            item-key="id"
            hide-default-header
            hide-default-footer
            :page.sync="paginator.page"
            :items-per-page="paginator.itemsPerPage"
            @page-count="paginator.pageCount = $event"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-start" width="20%">
                    <v-menu
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :plain="true"
                          :x-small="true"
                          color="secondary"
                        >
                          <v-icon>
                            {{
                              attrs['aria-expanded'] === 'true'
                                ? icons.mdiClose
                                : icons.mdiDotsVertical
                            }}
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="openViewFacilityModal(item)">
                          <v-list-item-title>View list</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editList(item)">
                          <v-list-item-title>Edit list</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteList(item)">
                          <v-list-item-title>Delete list</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :plain="true"
                          :x-small="true"
                          color="primary"
                          @click="toggleFavorite(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ item.is_favorite ? icons.mdiStar : icons.mdiStarOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ item.is_favorite ? 'Remove from favorites' : 'Add to favorites' }}
                      </span>
                    </v-tooltip>
                  </td>
                  <td width="60%">
                    <div class="d-flex gap-10">
                      <div class="circle" :style="circleStyle(item.color)"></div>
                      {{ item.title }}
                    </div>
                  </td>
                  <td class="text-end" width="20%">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :plain="true"
                          :x-small="true"
                          color="default"
                          @click="useFacilityPlaylist(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiSync }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Sync Facility</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :plain="true"
                          :x-small="true"
                          color="primary"
                          @click="openViewFacilityModal(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>View Facility Playlist</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <pagination
            :page.sync="paginator.page"
            :items-per-page.sync="paginator.itemsPerPage"
            :page-count="paginator.pageCount"
          ></pagination>
        </v-tab-item>

        <v-tab href="#myFavoriteFacilities">
          My Favorites
        </v-tab>
        <v-tab-item
          class="borderless-tabs"
          value="myFavoriteFacilities"
        >
          <v-data-table
              class="w-100"
              :headers="allTabHeaders"
              :items="myFacilityPlaylistFavorites"
              item-key="id"
              show-expand
              single-expand
              hide-default-header
              hide-default-footer
              :page.sync="favoritesPaginator.page"
              :items-per-page="favoritesPaginator.itemsPerPage"
              @page-count="favoritesPaginator.pageCount = $event"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-start" width="20%">
                      <v-menu
                        offset-y
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :plain="true"
                            :x-small="true"
                            color="secondary"
                          >
                            <v-icon>
                              {{
                                attrs['aria-expanded'] === 'true'
                                  ? icons.mdiClose
                                  : icons.mdiDotsVertical
                              }}
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click="openViewFacilityModal(item)">
                            <v-list-item-title>View list</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="editList(item)">
                            <v-list-item-title>Edit list</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteList(item)">
                            <v-list-item-title>Delete list</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :plain="true"
                            :x-small="true"
                            color="primary"
                            @click="toggleFavorite(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>{{ item.is_favorite ? icons.mdiStar : icons.mdiStarOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          {{ item.is_favorite ? 'Remove from favorites' : 'Add to favorites' }}
                        </span>
                      </v-tooltip>
                    </td>
                    <td width="60%">
                      <div class="d-flex gap-10">
                        <div class="circle" :style="circleStyle(item.color)"></div>
                        {{ item.title }}
                      </div>
                    </td>
                    <td class="text-end" width="20%">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :plain="true"
                            :x-small="true"
                            color="default"
                            @click="useFacilityPlaylist(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>{{ icons.mdiSync }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Use Facility</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :plain="true"
                            :x-small="true"
                            color="primary"
                            @click="openViewFacilityModal(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>View Facility Playlist</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            <pagination
              :page.sync="favoritesPaginator.page"
              :items-per-page.sync="favoritesPaginator.itemsPerPage"
              :page-count="favoritesPaginator.pageCount"
            ></pagination>
        </v-tab-item>
      </v-tabs>
    </v-col>

    <CreateUpdateFacilityPlaylist
      v-model="showCreatePlaylistModal"
      :openCreateModal="showCreatePlaylistModal"
      :createPayLoad="payLoad"
      @close="showCreatePlaylistModal = false"
    />

    <ViewFacilityPlaylist
      v-model="showViewPlaylistModal"
      :showViewPlaylistModal="showViewPlaylistModal"
      :facilityPlaylist="facilityPlaylist"
      @close="showViewPlaylistModal = false"
    />
  </v-row>
</template>

<script>
import CreateUpdateFacilityPlaylist from "@/components/elements/modals/FacilityPlaylist/CreateUpdateFacilityPlaylist.vue";
import FacilityPlaylist from '@/mixins/FacilityPlaylist';
import SyncEncounters from '@/mixins/SyncEncounters';
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue';
import {
  mdiClose,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPlus,
  mdiStar,
  mdiStarOutline,
  mdiSync,
} from '@mdi/js';
import { mapGetters } from 'vuex';
import ViewFacilityPlaylist from '../elements/modals/FacilityPlaylist/ViewFacilityPlaylist.vue';

export default {
  components: {
    AppCardActions,
    ViewFacilityPlaylist,
    CreateUpdateFacilityPlaylist
  },
  mixins: [FacilityPlaylist, SyncEncounters],
  data() {
    return {
      showViewPlaylistModal: false,
      facilityPlaylist: null,
      allTabHeaders: [
        {
          text: 'First Actions', value: 'first_actions', sortable: false,
        },
        {
          text: 'Name', value: 'title', sortable: false,
        },
        {
          text: 'Last Actions', value: 'last_actions', sortable: false,
        },
      ],
      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiStar,
        mdiStarOutline,
        mdiEyeOutline,
        mdiClose,
        mdiSync,
      },
      paginator: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      favoritesPaginator: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
    }
  },
  computed: {
    ...mapGetters('auth', { myFacilityPlaylist: 'facilityPlaylist' }),
    myFacilityPlaylistFavorites() {
      if (!this.myFacilityPlaylist) {
        return [];
      }

      return this.myFacilityPlaylist.filter(item => item.is_favorite);
    }
  },
  methods: {
    openViewFacilityModal(item) {
      this.showViewPlaylistModal = true
      this.facilityPlaylist = item
    },

    openCreatePlaylistModal() {
      this.showCreatePlaylistModal = true
      this.payLoad = {
        id: null,
        title: '',
        color: '#FF0000',
        is_favorite: false,
        place_of_service_ids: [],
      }
    },

    async useFacilityPlaylist(item) {
      const isOnline = this.checkIfOnline();

      if (!isOnline) {
        return;
      }

      const facilitiesList = item.place_of_services
        .map(facility => `<li>${facility.title}</li>`)
        .join('');

      const isConfirmed = await this.$root.confirm({
        title: 'Using Playlist',
        subTitle: `Are you sure you want to use this "${item.title}" playlist's facilities?`,
        body:
          `<ul>
            ${facilitiesList}
          </ul>`,
        html: true,
        cancel: "No",
        confirm: "Yes",
        confirmColor: "primary",
      })

      if (isConfirmed) {
        this.$store.dispatch('encounters/syncingModal', true)

        const isSuccess = await this.$store.dispatch('auth/useFacilityPlaylist', {
          playlistId: item.id,
          syncLog: this.syncLogSetup('Facility Playlist: Use'),
        })

        if (isSuccess) {
          try {
            await this.startSync()

            this.$store.dispatch('encounters/syncingModal', false)

          } catch (error) {
            console.error(error)
            this.$store.dispatch('notify', { value: `Error syncing all data: ${error}`, color: 'error' })
          } finally {
            this.$store.dispatch('encounters/syncingModal', false)
          }
        } else {
          this.$store.dispatch('encounters/syncingModal', false)
        }
      }
    },
  }
}
</script>

<style lang="scss">
.playlist-text-field {
  .v-input__control {
    .v-text-field__slot {
      .v-label {
        top: 5px !important
      }
    }
  }
}

.ml-5 {
  margin-left: 5px;
}

.gap-10 {
  gap: 10px;
}

.w-100 {
  width: 100%;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.v-pagination li button {
  min-height: 30px !important;
  min-width: 30px !important;
}
</style>
