import { ConnectToInternetMessage } from '@/@core/utils/wordings';
import syncLogSetup from '@/mixins/SyncEncounters';
import Vue from 'vue';

export default {
  mixins: [syncLogSetup],
  data() {
    return {
      showCreatePlaylistModal: false,
      payLoad: {
        id: null,
        title: '',
        color: '#FF0000',
        is_favorite: false,
        place_of_service_ids: [],
      },
    }
  },
  methods: {
    editList(item) {
      this.showCreatePlaylistModal = true
      this.payLoad = {
        id: item.id,
        title: item.title,
        color: item.color,
        is_favorite: item.is_favorite,
        place_of_service_ids: item.place_of_services.map(place_of_service => place_of_service.id),
      }
    },

    close() {
      this.$emit('close');
    },

    checkIfOnline() {
      const isOnline = navigator.onLine;
      if (!isOnline) {
        Vue.store.dispatch('notify', { value: ConnectToInternetMessage, color: 'error' })
      }

      return isOnline;
    },

    async deleteList(item) {
      const isOnline = this.checkIfOnline();
      if (!isOnline) {
        return false;
      }

      const isConfirmed = await this.$root.confirm({
        title: 'Deleting Playlist',
        subTitle: `Are you sure you want to delete "${item.title}"?`,
        cancel: "No",
        confirm: "Delete",
        confirmColor: "error",
      })

      if (isConfirmed) {
        this.$store.dispatch('encounters/syncingModal', true)
        await this.$store.dispatch('auth/deleteFacilityPlaylist', {
          playlistId: item.id,
          syncLog: this.syncLogSetup('Facility Playlist: Delete'),
        })
        this.$store.dispatch('encounters/syncingModal', false)
      }

      return isConfirmed;
    },

    async toggleFavorite(item) {
      const isOnline = this.checkIfOnline();
      if (!isOnline) {
        return false;
      }

      item.is_favorite = !item.is_favorite;

      this.myFacilityPlaylist.forEach((playlist, index) => {
        if (playlist.id === item.id) {
          this.myFacilityPlaylist[index] = item
        }
      });
      this.$store.dispatch('encounters/syncingModal', true)
      await this.$store.dispatch('auth/syncFacilityPlaylist', {
        ...item,
        place_of_service_ids: item.place_of_services.map(place_of_service => place_of_service.id),
        sync_log: this.syncLogSetup('Facility Playlist: Sync(Toggle)'),
      })
      this.$store.dispatch('encounters/syncingModal', false)
    },

    circleStyle(color) {
      return {
        backgroundColor: color,
      }
    },
  }
}
